.background {
    width: 70vw;
    height: 60vh;
    position: fixed;
    top: 20vh;
    left: 15vw;
    background-color: white;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.imagePreview {
    height: 45vh;
    width: calc(45vh*0.822);
    border-radius: 15px;

}

.takePictureButton {
    width: 20vw;
    height: 4vh;
    border-radius: 15px;
    background-color: #005cb8; 
    margin-top: 2vh;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    margin-bottom: 2vh;
}

.row {
    width: 100%;
    display: flex;
    flex-direction: row;
    margin-bottom: 2vh;
    margin-left: 2vw;
}

.backIcon {
    height: 40%
}

.printImage {
    height: 2cm;
    width: 2cm
}

.loadingIcon {
    height: 80%;
    color: #FFF;
    -webkit-animation: spin 4s linear infinite;
    -moz-animation: spin 4s linear infinite;
    animation: spin 4s linear infinite;
}

@-moz-keyframes spin { 
    100% { -moz-transform: rotate(360deg); } 
}
@-webkit-keyframes spin { 
    100% { -webkit-transform: rotate(360deg); } 
}
@keyframes spin { 
    100% { 
        -webkit-transform: rotate(360deg); 
        transform:rotate(360deg); 
    } 
}