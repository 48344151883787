.background {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-image: url("../../public/assets/images/screen_background.png");
}

.jjLogo {
    height: 5vh;
    margin-bottom: 2vh;
    margin-top: 2vh;
}

.takePictureButton {
    width: 20vw;
    height: 8vh;
    border-radius: 15px;
    background-color: #FFF; 
    margin-top: 2vh;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
}

.cameraIcon {
    height: 80%;
    color: white
}

.camera{
    border-radius: 15px;
    width: calc(60vh*0.822);
    height: 60vh;
    object-fit: cover;
}

.timer {
    z-index: 10;
    color: white;
    position: fixed;
    left: 48%;
    top: 30%;
    font-size: 80px;
}

.cameraDiv{
    border-radius: 15px;
    height: 60vh;
    width: calc(60vh*0.822);
}

.nextLevelLogo {
    position: fixed;
    bottom: 2vh;
    right: 2vw;
    width: 10vw;
}

.invisible {
    position: fixed;
    top: -200vh
}